import { useAtomValue } from "jotai"
import { podiumAtom } from "../../../../state/racers"
import { PodiumSpot } from "./podiumSpot";


export const Podium = () => {
    const racers = useAtomValue(podiumAtom);

    return <div className="podium">
        <PodiumSpot position="1st" racer={racers[0]} />
        <PodiumSpot position="2nd" racer={racers[1]} />
        <PodiumSpot position="3rd" racer={racers[2]} />
    </div>
}