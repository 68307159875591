import { useAtomValue } from "jotai"
import { Color } from "./color"

import './header.css'
import { scoutsListAtom } from "../../../state/scouting"

export const Header = () => {
    const scoutList = useAtomValue(scoutsListAtom)
    return <div className="header row">
        <Color />
        <div style={{display: "flex", flexDirection: 'row'}}>
            {
                scoutList.join(', ')
            }
        </div>
    </div>
}