import { useEffect } from 'react';

import { useAtomValue, useSetAtom } from 'jotai';
import { loggedInAtom, usernameAtom, userRoleAtom } from '../state/general';
import { Intro } from './intro/intro';
import { Scouting } from './scouting/scouting';
import { Restreaming } from './restream/restream';
import { Commentary } from './commentary/commentary';

export const ScoutTool = () => {
	const setIsLoggedIn = useSetAtom(loggedInAtom)
	const setUsername = useSetAtom(usernameAtom)
    const userRole = useAtomValue(userRoleAtom)

	useEffect(() => {
		fetch('/loggedIn')
			.then(res => res.json())
			.then(res => {
				if(res.status){
					setIsLoggedIn(true);
					setUsername(res.username);
					
					// const broadcastChannel = new BroadcastChannel(`ScoutTool${res.username}`);
					// broadcastChannel.onmessage = receiveFromTab;
					// setBroadcastChannel(broadcastChannel);
				}
			})
	}, []);
    
    if(!userRole){
        return <Intro />
    }

	if(userRole === 'Commentary'){
		return <Commentary />
	}

	if(userRole === 'Restream'){
		return <Restreaming />
	}

	if(userRole === 'Scout'){
		return <Scouting />
	}
};
