import {useEffect, useRef} from 'react';

import Twitch from '../twitchEmbedv1.js'
import { useAtom } from 'jotai';
import { racersAtom } from '../../../../state/racers.js';

interface RetimePlayerProps {
    playSounds: boolean
    racerName: string
    vodId: string
}

export const RetimePlayer = ({playSounds, racerName, vodId}: RetimePlayerProps) => {
	const playerRef = useRef(null);
	const playerContainerRef = useRef<HTMLDivElement>(null)
	const [racers, setRacers] = useAtom(racersAtom)
	//on start
	useEffect(() => {
		createPlayer();
	}, []);

	useEffect(() => {
		if(!playerRef.current){
			return
		}
		const newRacers = {...racers}
		const newRacer = {...racers[racerName]}
		newRacer.twitchPlayer = playerRef.current
		newRacers[racerName] = newRacer
		setRacers(newRacers)
	}, [playerRef.current])

	useEffect(() => {
		if(!playerRef.current){
			return;
		}
		playerRef.current.setMuted(!playSounds);
	}, [playSounds]);

	const createPlayer = () => {
		const options = {
			width: 360,
			height: 240,
			video: vodId,
			controls: true
		};
		const newPlayer = new Twitch.Player(racerName, options);
		newPlayer.setMuted(!playSounds);

		newPlayer.addEventListener(Twitch.Player.ONLINE, () => {
			newPlayer.play();
			newPlayer.setQuality('360p');
			newPlayer.setMuted(!playSounds);
		});
		newPlayer.addEventListener(Twitch.Player.AUTHENTICATE, a => {
			//setIsAuthenticated(true)
		})

		playerRef.current = newPlayer;
	}
	
	return <div ref={playerContainerRef} id={racerName} className='stream' />
}