import { Header } from './header/header'
import { RacerList } from './racerList/racerList'
import { Streams } from './streams/streams'
import './scouting.css'

import { disconnectSocket, initiateSocket } from '../../../sockets'
import { useEffect } from 'react'
import { useAtomValue } from 'jotai'
import { Settings } from './settings/settings'
import { raceTypeAtom, userColorAtom, usernameAtom } from '../../state/general'

export const Scouting = () => {
    const userColor = useAtomValue(userColorAtom)
    const username = useAtomValue(usernameAtom)
    const raceType = useAtomValue(raceTypeAtom)

    useEffect(() => {
        initiateSocket({
            color: userColor,
            raceType,
            role: 'scout',
            username
        })

        return () => {
            disconnectSocket()
        }
    }, [])

    return <div className='scoutToolContainer column'>
        <Header />
        <div className='scoutingContainer row'>
            <Streams />
            <RacerList />
        </div>
        <Settings />
    </div>
}