import { atom } from "jotai";
import { CommentaryView, Commentator, CreatorLevel, LevelRating, RacerStats } from "../types/commentator";
import { Racer } from "../types/race";
import { racersAtom } from "./racers";

// Commentator user state
export const commentatorsAtom = atom<Record<string, Commentator>>({})
export const commentatorListAtom = atom((get) => Object.values(get(commentatorsAtom))
                                                    .map(commentator => commentator.username)
                                                    .sort())

// Commentating state
export const currentCommentaryViewAtom = atom<CommentaryView>('race')
export const currentRacerToViewAtom = atom<string>('')
export const currentDetailedRacerAtom = atom((get) => {
    const racerName = get(currentRacerToViewAtom)
    const racers = get(racersAtom)
    return racers[racerName]
})
export const racerStatsAtom = atom<Record<string, RacerStats>>({})
export const creatorLevelsAtom = atom<Record<string, CreatorLevel[]>>({})
export const raceRatingsAtom = atom<LevelRating[]>([])