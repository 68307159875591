import { useAtom } from "jotai"
import { CommentaryView } from "../../../types/commentator"
import { currentCommentaryViewAtom } from "../../../state/commentary"
import classNames from 'classnames'

interface ViewSelectProps {
    title: string
    view: CommentaryView
}

export const ViewSelect = ({title, view}: ViewSelectProps) => {
    const [currentCommentaryView, setCommentaryView] = useAtom(currentCommentaryViewAtom)

    return <div className={classNames({
        commentaryViewSelect: true,
        selectedCommentaryView: currentCommentaryView === view
    })}
        onClick={() => setCommentaryView(view)}
    >
        {title}
    </div>
}