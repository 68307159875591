import { useAtom } from "jotai"
import { useEffect, useState } from "react"
import { isColor } from "../../../utils/color"
import { UserColorBlock } from "../../shared/userColorBlock"
import { userColorAtom } from "../../../state/general"
import { sendMessage } from "../../../../sockets"

export const Color = () => {
    const [userColor, setUserColor] = useAtom(userColorAtom)
    const [newUserColor, setNewUserColor] = useState("")

    useEffect(() => {
        if(isColor(newUserColor)){
            setUserColor(newUserColor)
        }
    }, [newUserColor])

    useEffect(() => {
        sendMessage('updateColor', newUserColor)
    }, [userColor])
    
    return  <label className='colorPicker'>
        Color: <input
            type='text'
            className='colorInput'
            onChange={e => setNewUserColor(e.target.value)}
            value={newUserColor}
        />
        &nbsp;<UserColorBlock color={userColor} />
    </label>
}