import { disconnectSocket, initiateSocket } from '../../../sockets'
import { useEffect, useState } from 'react'
import { useAtomValue } from 'jotai'

import './commentary.css'
import { StaffView } from './staff/staffView'
import { RaceView } from './race/raceView'
import { raceTypeAtom, usernameAtom } from '../../state/general'
import { CommentaryHeader } from './header/header'
import { currentCommentaryViewAtom } from '../../state/commentary'
import { LevelView } from './level/levelView'

export const Commentary = () => {
    const currentCommentaryView = useAtomValue(currentCommentaryViewAtom);
    const username = useAtomValue(usernameAtom);
    const raceType = useAtomValue(raceTypeAtom);

    useEffect(() => {
        initiateSocket({
            raceType,
            role: 'commentary',
            username
        });

        return () => {
            disconnectSocket();
        }
    }, [])

    return <div className='commentaryContainer'>
        <CommentaryHeader />
        {
            currentCommentaryView === 'race' && <RaceView />
        }
        {
            currentCommentaryView === 'level' && <LevelView />
        }
        {
            currentCommentaryView === 'staff' && <StaffView />
        }
    </div>
}