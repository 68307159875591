import { useEffect } from "react"
import { sendMessage } from "../../../../sockets"
import { useAtomValue } from "jotai"
import { creatorLevelsAtom, raceRatingsAtom } from "../../../state/commentary"


export const LevelView = () => {
    const raceRatings = useAtomValue(raceRatingsAtom)
    const pastCreatorLevels = useAtomValue(creatorLevelsAtom)

    useEffect(() => {
        sendMessage('getRaceStats', undefined)
    }, [])

    return <div className="levelContainer">
        <div className="raceRatingsContainer">
            <h2>Testers</h2>
            <ul>
                {raceRatings.map(rating => <li>{rating.user_name}</li>)}
            </ul>
        </div>
        <hr />
        <div className="pastLevelsContainer">
            <h2>Creator&apos;s Past Races</h2>
            {
                Object.entries(pastCreatorLevels).map(([creator, levels]) => <div className="creatorPastLevels">
                    <h3>{creator}</h3>
                    <table>
                    <thead>
                        <tr>
                            <td>Week</td>
                            <td>Level Name</td>
                            <td>Rating</td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            levels.map(level => <tr>
                                <td>{level.week}</td>
                                <td>{level.race_name}</td>
                                <td>{level.rating}</td>
                            </tr>)
                        }
                    </tbody>
                </table>
                </div>)
            }
        </div>
    </div>
}