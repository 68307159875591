import { useAtomValue } from "jotai"
import { isNotAuthenticatedInTwitchPlayerAtom } from "../../../state/scouting";

export const IsAuthenticatedWarning = () => {
    const isNotAuthed = useAtomValue(isNotAuthenticatedInTwitchPlayerAtom)

    if(!isNotAuthed){
        return null
    }

    return <div style={{color: "red"}}>
        "Warning! The Twitch video player does not think you are logged in. This means that even if you have Twitch Turbo, you will see ads."
    </div>
}