import { Racer as RacerType } from "../../../types/race"
import classNames from 'classnames'

import './racer.css'

interface RacerProps {
    racer: RacerType
}

export const Racer = ({racer}: RacerProps) => {
    return <div
            className={classNames({
                isFinished: racer.isFinished,
                isDNF: racer.level < 1,
                runner: true,
            })} 
        >
        {racer.level}.{racer.checkpoint} - {racer.name}
    </div>
}