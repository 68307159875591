import { Podium } from "./podium/podium"
import { RacerList } from "./racers/racerList"
import { RacerStats } from "./racers/racerStats/racerStats"

export const RaceView = () => {
    return <div className="raceView column">
        <Podium />
        <div className="racersContainer row">
            <RacerList />
            <RacerStats />
        </div>
    </div>
}