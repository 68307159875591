import { useAtomValue, useSetAtom } from "jotai"
import { raceTypeAtom, usernameAtom } from "../../state/general"

export const RaceTypeSelect = () => {
    const username = useAtomValue(usernameAtom)
    const setRaceType = useSetAtom(raceTypeAtom)

    return <div className='raceTypeSelectContainer'>
        <h2>
            Welcome, {username}
        </h2>
        <h2>Choose the Race Type</h2>
        <div className="raceTypeSelection" onClick={() => setRaceType('Blind Saturday')}>Blind Kaizo Race</div>
        <div className="raceTypeSelection" onClick={() => setRaceType('Mystery Monday')}>Mystery Monday</div>
        <div className="raceTypeSelection" onClick={() => setRaceType('Special Race')}>Other</div>
    </div>
}