import { useAtomValue } from "jotai"
import { Racer } from "../../../types/race"
import { Stream } from "./stream"
import { racersAtom } from "../../../state/racers"

interface StreamRowProps {
    checkpoint: string | number
    racersInGroup: Racer[]
}

export const StreamRow = ({
    checkpoint,
    racersInGroup,
}: StreamRowProps) => {
    
    const racers = useAtomValue(racersAtom)
    return <div className="streamRowContainer" >
        <h3 className="streamRowHeader">
            {checkpoint === 'isFinished' ? 'Finished' : `Section ${checkpoint}`}
        </h3>
        <div className='streamRow'>
            {
                racersInGroup.map(racer => <Stream racer={racers[racer.name]} key={racer.name}/>)
            }
        </div>
    </div>
}