import { useAtomValue } from "jotai"
import { useState } from "react"
import { sendMessage } from "../../../../sockets";
import { raceTypeAtom } from "../../../state/general";


export const AddRacer = () => {
    const [racerToAdd, setRacerToAdd] = useState('');
    const raceType = useAtomValue(raceTypeAtom);
    
    return <div>
        <input value={racerToAdd} onChange={e => setRacerToAdd(e.target.value)} />
        <button onClick={() => sendMessage('addRacer', {racerName: racerToAdd, raceType})}>Add Racer</button>
    </div>
}