import { useAtom, useAtomValue } from "jotai"
import { Racer as RacerType } from "../../../types/race"
import classNames from 'classnames'
import { UserColorBlock } from "../../shared/userColorBlock"
import { sendMessage } from "../../../../sockets"
import { scoutsAtom, watchedStreamsAtom } from "../../../state/scouting"

import './racer.css'

interface RacerProps {
    racer: RacerType
}

export const Racer = ({racer}: RacerProps) => {
    const scouts = useAtomValue(scoutsAtom)
    const [watchedStreams, setWatchedStreams] = useAtom(watchedStreamsAtom)

    const toggleWatch = () => {
        const isCurrentlyWatched = watchedStreams.includes(racer.name)
        sendMessage('watchUpdate', {
            racerName: racer.name,
            isWatched: !isCurrentlyWatched
        })

        if(isCurrentlyWatched){
            setWatchedStreams(watchedStreams.filter(stream => stream !== racer.name))
        } else {
            setWatchedStreams([...watchedStreams, racer.name])
        }
    }

    return <div
            className={classNames({
                isFinished: racer.isFinished,
                needsScout: racer.scoutsWatching.length === 0,
                runner: true,
            })} 
            onClick={toggleWatch}
        >
        {racer.level}.{racer.checkpoint} - {racer.name}
        {racer.scoutsWatching.map(scoutId => <UserColorBlock color={scouts[scoutId].color} key={scoutId} />)}
    </div>
}