import { useAtomValue } from "jotai"
import { Racer } from "../../../../types/race"
import { raceStartTimeAtom } from "../../../../state/general"
import { createTimeString } from "../../../../utils/time"

interface PodiumSpotProps {
    position: string
    racer?: Racer
}

export const PodiumSpot = ({position, racer}: PodiumSpotProps) => {
    const raceStartTime = useAtomValue(raceStartTimeAtom)

    return <div className="podiumSpot">
        <h3>{position}</h3>
        <div>{racer ? racer.name : 'TBD'}</div>
        <div>{racer ? createTimeString((racer.checkpointTimestamp - raceStartTime) / 1000) : 'TBD'}</div>
    </div>
}