import socketIOClient, { Socket } from 'socket.io-client';
import store from './scoutTool/state/store';
import { Racer, RaceType } from './scoutTool/types/race';
import { Scout } from './scoutTool/types/scout';
import { Commentator, CreatorLevel, LevelRating, RacerStats } from './scoutTool/types/commentator';
import { commentatorsAtom, creatorLevelsAtom, raceRatingsAtom, racerStatsAtom } from './scoutTool/state/commentary';
import { restreamersAtom } from './scoutTool/state/restreaming';
import { Restreamer } from './scoutTool/types/restreamer';
import { racersAtom } from './scoutTool/state/racers';
import { joinAllChatAtom, scoutsAtom } from './scoutTool/state/scouting';
import { raceStartTimeAtom } from './scoutTool/state/general';

let socket: Socket | undefined;
let connected = false;
let isReconnect = false;

interface InitiateSocket {
    color?: string
    raceType: RaceType
    role: 'commentary' | 'restream' | 'scout'
    username: string
}

export const initiateSocket = (data: InitiateSocket) => {
    socket = socketIOClient();
    socket.on("connect", () => {
        socket!.emit('init', data);
        connected = true;
    })

    socket.on("disconnect", () => {
        socket.close()
        socket = undefined;
        connected = false;
        isReconnect = true
        initiateSocket(data)
    })

    socket.on('currentState', (data: {
        commentators: Record<string, Commentator>,
        racers: Record<string, Racer>,
        restreamers: Record<string, Restreamer>,
        scouts: Record<string, Scout>,
        startTime: number
    }) => {
        store.set(commentatorsAtom, () => data.commentators)
        store.set(racersAtom, () => data.racers)
        store.set(restreamersAtom, () => data.restreamers)
        store.set(scoutsAtom, () => data.scouts)
        store.set(raceStartTimeAtom, () => data.startTime)
        if(isReconnect){
            // streamsToShowRef.current.forEach(runner => {
            //     sockets.sendMessage('watchUpdate', {runner, isWatched: true});
            // })
        }
    });

    socket.on('updateColor', (data: {scoutId: string, color: string}) => {
        store.set(scoutsAtom, (prevScouts) => {
            const newScouts = {...prevScouts}
            const scoutToUpdate = {...prevScouts[data.scoutId]}
            scoutToUpdate.color = data.color
            newScouts[data.scoutId] = scoutToUpdate
            return newScouts
        })
    });

    socket.on('addRacer', (name: string) => {
        store.set(racersAtom, (prevRacers) => {
            const newRacers = {
                ...prevRacers,
                [name]: {
                    checkpoint: 1,
                    checkpointTimestamp: 0,
                    isFinished: false,
                    level: 1,
                    name,
                    scoutsWatching: []
                }
            }

            return newRacers
        })
        if(store.get(joinAllChatAtom)){
            //TODO
            //sendToTab('join', name);
        }
    });

    socket.on('addCommentator', (data: Record<string, Commentator>) => {
        store.set(commentatorsAtom, (prevCommentators) => {return {...prevCommentators, ...data}});
    });

    socket.on('addRestreamer', (data: Record<string, Scout>) => {
        store.set(restreamersAtom, (prevRestreamers) => {return {...prevRestreamers, ...data}});
    });

    socket.on('addScout', (data: Record<string, Scout>) => {
        store.set(scoutsAtom, (prevScouts) => {return {...prevScouts, ...data}});
    });

    socket.on('removeRacer', (name: string) => {
        store.set(racersAtom, (prevRacers) => {
            const newRacers = {...prevRacers}
            delete newRacers[name];
            return newRacers;
        });

        store.set(scoutsAtom, (prevScouts) => {
            const newScouts = {};
            for(const scout in prevScouts) {
                newScouts[scout] = {...prevScouts[scout]}
                delete newScouts[scout].watchedRunners[name]
            }
            return newScouts;
        })
        
        if(store.get(joinAllChatAtom)){
            //TODO
            //sendToTab('part', name);
        }
    });

    socket.on('watchUpdate', (data: {
            racerName: string,
            isWatched: boolean,
            scoutId: string
        }) => {
        const {racerName, isWatched, scoutId} = data
        if(!store.get(racersAtom)[racerName]){
            return;
        }

        let newScouts = {...store.get(scoutsAtom)};
        let newScout = {...newScouts[scoutId]};
        let newRacers = {...store.get(racersAtom)};
        let newRacer = {...newRacers[racerName]}

        if(isWatched){
            newScout.watchedRacers.push(racerName);
            newScouts[scoutId] = newScout;
            newRacer.scoutsWatching.push(scoutId);
            newRacers[racerName] = newRacer;
        } else {
            newScout.watchedRacers = newScout.watchedRacers.filter(racer => racer !== racerName);
            newRacer.scoutsWatching = newRacer.scoutsWatching.filter(scout => scout !== scoutId);
        }

        newScouts[scoutId] = newScout
        newRacers[racerName] = newRacer

        store.set(scoutsAtom, () => newScouts);
        store.set(racersAtom, newRacers)
    });

    socket.on('racerStats', (data: RacerStats) => {
        store.set(racerStatsAtom, (prevStats) => {return {...prevStats, [data.racerName.toLowerCase()]: data}});
    });

    socket.on('removeCommentator', (id: string) => {
        store.set(commentatorsAtom, (prevUsers) => {
            const newUsers = {...prevUsers};
            delete newUsers[id];
            return newUsers;
        });
    })

    socket.on('removeRestreamer', (id: string) => {
        store.set(restreamersAtom, (prevUsers) => {
            const newUsers = {...prevUsers};
            delete newUsers[id];
            return newUsers;
        });
    })

    socket.on('removeScout', (id: string) => {
        store.set(scoutsAtom, (prevUsers) => {
            const newUsers = {...prevUsers};
            delete newUsers[id];
            return newUsers;
        });

        store.set(racersAtom, (prevRacers) => {
            const newRacers = {...prevRacers};
            for(const racer in newRacers){
                newRacers[racer].scoutsWatching = newRacers[racer].scoutsWatching.filter(scout => scout !== id)
            }
            Object.entries(newRacers).forEach(([name, data]) => {
                delete data.scoutsWatching[id];
                newRacers[name] = data; 
            })
            return newRacers;
        })
    })

    socket.on('runnerProgress', (data: {
        checkpoint: number
        checkpointTimestamp: number 
        level: number
        racerName: string
    }) => {
        store.set(racersAtom, (prevRacers) => {
            const {racerName, level, checkpoint, checkpointTimestamp} = data;
            const newRacers = {...prevRacers};
            const newRacer = {
                ...newRacers[racerName],
                level,
                checkpoint,
                checkpointTimestamp,
            };
            
            newRacers[racerName] = newRacer;
            return newRacers;
        });
        
        //TODO
        // if(autoToggleRef.current){
        //     const racer = newRacers[data.racerName];
        //     if(racer.level === autoOpenAtRef.current[0] && racer.checkpoint === autoOpenAtRef.current[1] && !streamsToShowRef.current.includes(data.racerName)){
        //         toggleStream(data.racerName);
        //     }
        // }

    })

    socket.on('finishedUpdate', (data: {
        checkpointTimestamp: number
        isFinished: boolean
        racerName: string
    }) => {
        store.set(racersAtom, (prevRacers) => {
            const {racerName, isFinished, checkpointTimestamp} = data;
            let newRacers = {...prevRacers};
            let newRacer = {
                ...newRacers[racerName],
                isFinished,
                checkpointTimestamp,
            };

            newRacers[racerName] = newRacer;
            return newRacers;
        });
    })

    socket.on('startTime', (timestamp) => {
        store.set(raceStartTimeAtom, () => timestamp)
    });

    socket.on('raceStats', (data: {ratings: LevelRating[], creatorLevels: Record<string, CreatorLevel[]>}) => {
        store.set(raceRatingsAtom, () => {
            return data.ratings
        })

        store.set(creatorLevelsAtom, () => {
            return data.creatorLevels
        })
    })
}

export const disconnectSocket = () => {
    if (socket) {
        socket.disconnect();
        socket.close();
    }
}

export const sendMessage = (type: string, message: any) => {
    if(socket && connected){
        socket.emit(type, message);
    }
}
