import { atom } from "jotai";
import { UserRole } from "../types/general";
import { RaceType } from "../types/race";
import { atomWithStorage } from "jotai/utils";
import { createRandomColor } from "../utils/color";

export const raceStartTimeAtom = atom(Date.now())
export const raceTypeAtom = atom<RaceType | undefined>(undefined)
export const userRoleAtom = atom<UserRole | undefined>(undefined)
export const usernameAtom = atom('')
export const loggedInAtom = atom(false)
export const userColorAtom = atomWithStorage<string | null>('color', createRandomColor(), undefined, {getOnInit: true})