import glitchIcon from '../../../../../../images/Twitch_Glitch_Logo_Purple.svg'

interface HeaderProps {
    racerName: string
}

export const Header = ({racerName}: HeaderProps) => {
    return <h2>
        {racerName} - <a href={`https://twitch.tv/${racerName}`} target="_blank"><img className="twitchLinkLogo" src={glitchIcon} /></a>
    </h2>
}