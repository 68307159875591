import { useAtomValue, useSetAtom } from "jotai"
import { usernameAtom, userRoleAtom } from "../../state/general"

export const RoleTypeSelect = () => {
    const username = useAtomValue(usernameAtom)
    const setRole = useSetAtom(userRoleAtom)

    return <div className='raceTypeSelectContainer'>
        <h2>
            Welcome, {username}
        </h2>
        <h2>Choose your Role</h2>
        <div className="raceTypeSelection" onClick={() => setRole('Commentary')}>Commentator</div>
        <div className="raceTypeSelection" onClick={() => setRole('Restream')}>Restreamer</div>
        <div className="raceTypeSelection" onClick={() => setRole('Scout')}>Scout</div>
    </div>
}