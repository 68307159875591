import { useAtomValue } from "jotai";
import { Racer } from "./racer";
import { racersAtom, racersOrderAtom } from "../../../../state/racers";

export const RacerList = () => {
    const racers = useAtomValue(racersAtom)
    const racersOrder = useAtomValue(racersOrderAtom)
    
    if (Object.keys(racers).length === 0) {
        return <div className="racerList column">No signups.</div>
    }

    return <div
        className="racerList column"
    >
        {
            racersOrder.map((racer) => <Racer racer={racers[racer]} key={racer} /> )
        }
        <p>
            {racersOrder.length} racers
        </p>
    </div>
}