import {createRoot} from 'react-dom/client';
import { Provider } from "jotai/react";
import { ScoutTool } from "./components/index";
import store from './state/store';

import './scoutTool.css'

const container = document.getElementById('app');
const App = () => {
	return <Provider store={store}>
		<ScoutTool />
	</Provider>
}
const root = createRoot(container)
root.render(<App />)