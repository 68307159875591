import { Racer as RacerType } from "../../../../types/race"
import classNames from 'classnames'
import { useSetAtom } from "jotai"
import { currentRacerToViewAtom } from "../../../../state/commentary"

interface RacerProps {
    racer: RacerType
}

export const Racer = ({racer}: RacerProps) => {
    const setCurrentRacerToView = useSetAtom(currentRacerToViewAtom)
    return <div
            className={classNames({
                isFinished: racer.isFinished,
                isDNF: racer.level < 1,
                runner: true,
            })} 
            onClick={() => setCurrentRacerToView(racer.name)}
        >
        {racer.level}.{racer.checkpoint} - {racer.name}
    </div>
}