import { UserRole } from "../../../types/general"


interface UserListProps {
    role: string
    users: string[]
}

export const UserList = ({role, users}: UserListProps) => {
    return <div className='userList'>
        <h3>{role}</h3>
        <ul>
            {
                users.map(user => <li key={user}>{user}</li>)
            }
        </ul>
    </div>
}