import { useAtomValue } from "jotai"
import { sendMessage } from "../../../../sockets"
import { Racer } from "../../../types/race"
import { FinishedTime } from "./finishedTime"
import { raceTypeAtom } from "../../../state/general"
import { RetimeVodControls } from "./retiming/retimeVodControls"

interface StreamControlsProps {
    playSounds: boolean
    racer: Racer
    setPlaySounds: (isEnabled: boolean) => void
    setRetimeVod: (retimeVod: string) => void
}

export const StreamControls = ({
    playSounds,
    racer,
    setPlaySounds,
    setRetimeVod
}: StreamControlsProps) => {
    const raceType = useAtomValue(raceTypeAtom)

    const updateRacer = (racerName: string, level: number, checkpoint: number) => {
        sendMessage('runnerProgress', {
			checkpoint,
			level,
			racerName,
			raceType,
		})
    }


    if(racer.isFinished){
        return <div className="streamControls row">
            <RetimeVodControls setRetimeVod={setRetimeVod} racer={racer} />
            <label>
                &#9834;<input type='checkbox' checked={playSounds} onChange={(e) => setPlaySounds(!playSounds)} />
            </label>
            <FinishedTime racer={racer} />
        </div>
    }

    return <div className="streamControls row">
        <label>
            L: <input className='levelProgressInput' type='number' value={racer.level} readOnly={true} />
            <button className='levelProgressButton' onClick={() => updateRacer(racer.name, racer.level + 1, 1)} >+</button>
            <button className='levelProgressButton' onClick={() => updateRacer(racer.name, racer.level - 1, 1)} >-</button>
        </label>
        <label>
            C: <input className='levelProgressInput' type='number' value={racer.checkpoint} readOnly={true} />
            <button className='levelProgressButton' onClick={() => updateRacer(racer.name, racer.level, racer.checkpoint + 1)} >+</button>
            <button className='levelProgressButton' onClick={() => updateRacer(racer.name, racer.level, racer.checkpoint - 1)} >-</button>
        </label>
        <label>
            &#9834;<input type='checkbox' checked={playSounds} onChange={(e) => setPlaySounds(!playSounds)} />
        </label>
        <FinishedTime racer={racer} />
    </div>
}