import { useEffect, useState } from "react";
import { Racer } from "../../../types/race";
import { useAtomValue } from "jotai";
import { sendMessage } from "../../../../sockets";
import { raceStartTimeAtom } from "../../../state/general";
import { createTimeString } from "../../../utils/time";


export const FinishedTime = ({racer}: {racer: Racer}) => {
	const [finishedTime, setFinishedTime] = useState('1:XX:XX');
    const startTime = useAtomValue(raceStartTimeAtom)

    const toggleFinished = () => {
        sendMessage('finishedUpdate', {
			isFinished: !racer.isFinished,
			racerName: racer.name,
			streamDelay: racer.twitchPlayer?.getPlaybackStats().hlsLatencyBroadcaster,
		})
    }

    useEffect(() => {
		if(!racer.isFinished){
			setFinishedTime('');
			return;
		}
		
		let racerTimeInSeconds = (racer.checkpointTimestamp - startTime) / 1000;
		setFinishedTime(createTimeString(racerTimeInSeconds))
	}, [racer.isFinished, startTime, racer.checkpointTimestamp]);

    return <div className="row">
        <label>
            &#10004;<input type='checkbox' checked={racer.isFinished} onChange={toggleFinished} />
        </label>
        <input type='text' className='finishedTime' value={finishedTime} readOnly={true} />
    </div>
}