import { useAtomValue } from "jotai"
import { useEffect, useState } from "react"
import { sendMessage } from "../../../../sockets";
import { raceStartTimeAtom } from "../../../state/general";


export const StartTime = () => {
    const timezoneOffset = (new Date).getTimezoneOffset() * 60 * 1000;
    const startTime = useAtomValue(raceStartTimeAtom);
    const [editedStartTime, setEditedStartTime] = useState(startTime);

    useEffect(() => {
        setEditedStartTime(startTime);
    }, [startTime])

    return <div>
        <input
            style={startTime !== editedStartTime ? { border: '1px solid red' } : undefined}
            type='datetime-local'
            value={(new Date(editedStartTime - timezoneOffset)).toISOString().slice(0, 19)}
            onChange={e => setEditedStartTime(e.target.valueAsNumber + timezoneOffset)}
        />
        <label> Race start time (local)</label> <button onClick={() => sendMessage('startTime', editedStartTime)}>Set Start Time</button>
    </div>
}