import { Racer } from "../types/race";

export const sortRacers = (racerA: Racer, racerB: Racer) => {
    //if one is finished, but not the other
    if (racerA.isFinished && !racerB.isFinished) {
        return -1;
    } else if (racerB.isFinished && !racerA.isFinished) {
        return 1;
    }
    //if both are finished
    if (racerA.isFinished && racerB.isFinished) {
        return racerA.checkpointTimestamp - racerB.checkpointTimestamp;
    }
    //if one is at a higher level
    if (racerA.level !== racerB.level) {
        return racerB.level - racerA.level;
    }
    //compare checkpoints
    if (racerB.checkpoint !== racerA.checkpoint) {
        return racerB.checkpoint - racerA.checkpoint;
    }

    //Race hasn't started? Alphabetical
    if (racerB.checkpointTimestamp === racerA.checkpointTimestamp) {
        return racerA.name.toUpperCase() > racerB.name.toUpperCase() ? 1 : -1;
    } else {
        return racerA.checkpointTimestamp - racerB.checkpointTimestamp;
    }
}