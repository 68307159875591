import { useAtomValue } from 'jotai'
import { Racer } from '../../../types/race'
import { StreamRow } from './streamRow'
import { watchedStreamsAtom } from '../../../state/scouting'
import { racersAtom, racersOrderAtom } from '../../../state/racers'

export const Streams = () => {
    const watchedStreams = useAtomValue(watchedStreamsAtom)
    const racersOrder = useAtomValue(racersOrderAtom)
    const racers = useAtomValue(racersAtom)

    const groups = createStreamGroups(racersOrder.filter(racer => watchedStreams.includes(racer)), racers)
    return <div className='streamsContainer column'>
        {
            groups.map(({checkpoint, racers}) => <StreamRow
                checkpoint={checkpoint}
                racersInGroup={racers}
                key={checkpoint}
            />)
        }
    </div>
}

const createStreamGroups = (watchedStreams: string[], racers: Record<string, Racer>) => {
    const groups: Record<number, Racer[]> = {}
    const groupArrays: {checkpoint: string, racers: Racer[]}[] = []

    watchedStreams.forEach(racerName => {
        const racer = racers[racerName]
        let checkpoint = racer.isFinished ? 'isFinished' : createCheckpointNumber(racer.level, racer.checkpoint);

        if(!groups[checkpoint]){
            groups[checkpoint] = [racer]
        } else {
            groups[checkpoint].push(racer)
        }
    })
    
    Object.keys(groups).sort().reverse().forEach(key => groupArrays.push({checkpoint: key, racers: groups[key]}))
    return groupArrays
}

const createCheckpointNumber = (level: number, checkpoint: number) => {
    return Number(`${level}.${checkpoint}`)
}