import { useAtomValue } from "jotai"
import { currentDetailedRacerAtom, racerStatsAtom } from "../../../../../state/commentary"
import { Header } from "./header"
import { StatCard } from "./statCard"
import { createTimeString } from "../../../../../utils/time"
import { raceStartTimeAtom } from "../../../../../state/general"
import { useEffect } from "react"
import { sendMessage } from "../../../../../../sockets"

export const RacerStats = () => {
    const currentDetailedRacer = useAtomValue(currentDetailedRacerAtom)
    const raceStartTime = useAtomValue(raceStartTimeAtom)
    const racerStats = useAtomValue(racerStatsAtom)

    useEffect(() => {
        if(currentDetailedRacer){
            sendMessage('getRacerStats', currentDetailedRacer.name)
        }
    }, [currentDetailedRacer])

    if(!currentDetailedRacer){
        return <div className="racerStats">
            Choose a racer on the left to view some details about them.
        </div>
    }

    const {name, checkpoint, level, checkpointTimestamp} = currentDetailedRacer;
    const {placements} = racerStats[currentDetailedRacer.name.toLowerCase()] || {placements: {}}
    
    return <div className="racerStats column">
        <Header racerName={currentDetailedRacer.name} />
        <div className="statCardContainer">
            <StatCard title="Pronouns">
                Under Construction
            </StatCard>
            <StatCard title="Time on Section">
                {createTimeString((Date.now() - (checkpoint === 1 && level === 1 ? raceStartTime : checkpointTimestamp)) / 1000)}
            </StatCard>
            <StatCard title="Completed Races">
                {Object.values(placements).reduce((sum: number, val: number) => sum + val, 0) as number}
            </StatCard>
            <StatCard title="Times on Podium">
                <table className="podium">
                    <tbody>
                        <tr>
                            <td>First</td>
                            <td>{placements[1] || '-'}</td>
                        </tr>
                        <tr>
                            <td>Second</td>
                            <td>{placements[2] || '-'}</td>
                        </tr>
                        <tr>
                            <td>Third</td>
                            <td>{placements[3] || '-'}</td>
                        </tr>
                    </tbody>
                </table>
            </StatCard>
        </div>
    </div>
}