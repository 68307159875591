import {useEffect, useRef} from 'react';

import Twitch from './twitchEmbedv1.js'
import { useAtom, useSetAtom } from 'jotai';
import { racersAtom } from '../../../state/racers.js';
import { isNotAuthenticatedInTwitchPlayerAtom } from '../../../state/scouting.js';

interface TwitchAuthProps {
	displayName?: string
	id?: string
	profileImageUrl?: string
	__typename: string
}

export const TwitchPlayer = ({playSounds, racerName}) => {
	const setIsNotAuthenticated = useSetAtom(isNotAuthenticatedInTwitchPlayerAtom)
	const playerRef = useRef(null);
	const playerContainerRef = useRef<HTMLDivElement>(null)
	const maxDelay = 10
	const [racers, setRacers] = useAtom(racersAtom)
	//on start
	useEffect(() => {
		createPlayer();
	}, []);

	//Check delay between you and streamer and refresh if needed
	useEffect(() => {
		if(!playerRef.current){
			return
		}
		const timer = setInterval(() => {
			const delay = playerRef.current.getPlaybackStats().hlsLatencyBroadcaster;

			if(delay > (maxDelay || 10)){
				const playerContainer = playerContainerRef.current;
				playerContainer.removeChild(playerContainer.firstChild);
				createPlayer();
			}
		}, 2000);

		return () => {
			clearInterval(timer);
		}
	}, [maxDelay, playerRef.current]);

	useEffect(() => {
		if(!playerRef.current){
			return
		}
		const newRacers = {...racers}
		const newRacer = {...racers[racerName]}
		newRacer.twitchPlayer = playerRef.current
		newRacers[racerName] = newRacer
		setRacers(newRacers)
	}, [playerRef.current])

	useEffect(() => {
		if(!playerRef.current){
			return;
		}
		playerRef.current.setMuted(!playSounds);
	}, [playSounds]);

	const createPlayer = () => {
		const options = {
			width: 360,
			height: 240,
			channel: racerName,
			controls: false
		};
		const newPlayer = new Twitch.Player(racerName, options);
		newPlayer.setMuted(!playSounds);

		newPlayer.addEventListener(Twitch.Player.ONLINE, () => {
			newPlayer.play();
			newPlayer.setQuality('360p');
			newPlayer.setMuted(!playSounds);
		});
		newPlayer.addEventListener(Twitch.Player.AUTHENTICATE, (a: TwitchAuthProps) => {
			if(!a?.id){
				setIsNotAuthenticated(true)
			}
		})

		playerRef.current = newPlayer;
	}
	
	return <div ref={playerContainerRef} id={racerName} className='stream' />
}