import { useState } from "react"
import { Racer } from "../../../../types/race"
import { sendMessage } from "../../../../../sockets"

interface RetimeVodControlsProps {
    setRetimeVod: (retimeVod: string) => void
    racer: Racer
}

export const RetimeVodControls = ({setRetimeVod, racer}: RetimeVodControlsProps) => {
    const {twitchPlayer} = racer
    const [userVideos, setUserVideos] = useState<{id: string, published_at: string}[]>([])
    const [selectedRetimeVod, setSelectedRetimeVod] = useState('')

    const getUserVideos = async () => {
        const response = await fetch(`/queries/user/videos/${twitchPlayer.getChannelId()}`)
        if(response.ok){
            const vods = await response.json()
            setUserVideos(vods)
        }
    }

    const selectRetimeVod = (vod: string) => {
        setSelectedRetimeVod(vod)
        setRetimeVod(vod)
    }

    const retime = () => {
        const vod = userVideos.find(video => video.id === selectedRetimeVod)
        const publishedAt = new Date(vod.published_at)
        const finishedtime = publishedAt.getTime() + Math.floor(twitchPlayer.getCurrentTime() * 1000)
        sendMessage('retimeRacer', {racerName: racer.name, newTime: finishedtime})
        window.alert(`The racer has been retimed, but the server cannot currently (easily) handle this. Please let an RHR admin know to run the retime user command in Discord with the new finish time.`)
    }

    return <>
        {
            userVideos.length === 0 && <button onClick={getUserVideos}>Get VoDs</button>
        }
        {
            userVideos.length > 0 && <button onClick={retime}>Update Time</button>
        }
        <select onChange={(e) => selectRetimeVod(e.target.value)} value={selectedRetimeVod}>
            <option value=''>Select a VoD ID</option>
            {
                userVideos.map(vod => <option value={vod.id} key={vod.id}>{vod.id}</option>)
            }
        </select>
    </>
}