import { atom } from "jotai"
import { Racer } from "../types/race"
import { sortRacers } from "../utils/racers"

export const racersAtom = atom<Record<string, Racer>>({})
export const racersOrderAtom = atom((get) => {
    const racers = get(racersAtom)
    return Object.values(racers).sort(sortRacers).map(racer => racer.name)
})
export const podiumAtom = atom((get) => {
    const racers = get(racersAtom);
    const topThree = get(racersOrderAtom)
        .slice(0, 3)
        .map(racer => racers[racer])
        .filter(racer => racer.isFinished);
    return topThree;
})