import { useState } from 'react';
import { TwitchPlayer } from './twitchPlayer';
import { Racer } from '../../../types/race';
import { StreamControls } from './streamControls';

import './streams.css'
import { RetimePlayer } from './retiming/retimeVodPlayer';

interface StreamProps {
	racer: Racer
}

export const Stream = ({ racer }: StreamProps) => {
	const [playSounds, setPlaySounds] = useState(false);
	const [retimeVod, setRetimeVod] = useState('');

	return <div className='streamContainer column' >
		<div className='streamName'>
			<a href={`https://twitch.tv/${racer.name}`} target='_blank' rel="noreferrer noopener">{racer.name}</a>
		</div>
		{
			!retimeVod && <TwitchPlayer racerName={racer.name} playSounds={playSounds} />
		}
		{
			retimeVod && <RetimePlayer racerName={racer.name} playSounds={playSounds} vodId={retimeVod} />
		}
		<StreamControls playSounds={playSounds} setPlaySounds={setPlaySounds} setRetimeVod={setRetimeVod} racer={racer} />
	</div>
}
