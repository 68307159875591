import { AddRacer } from "./addRacer"
import { IsAuthenticatedWarning } from "./isAuthenticatedWarning"
import { StartTime } from "./startTime"

export const Settings = () => {
    return <div className='settingsContainer'>
        <IsAuthenticatedWarning />
        <StartTime />
        <AddRacer />
    </div>
}