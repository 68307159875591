import { useAtomValue } from "jotai"
import { commentatorListAtom } from "../../../state/commentary"
import { restreamersListAtom } from "../../../state/restreaming";
import { UserList } from "./userList";
import { scoutsListAtom } from "../../../state/scouting";

export const StaffView = () => {
    const commentators = useAtomValue(commentatorListAtom);
    const restreamers = useAtomValue(restreamersListAtom);
    const scouts = useAtomValue(scoutsListAtom);
    
    return <div className='staffContainer'>
        <UserList role="Commentators" users={commentators} />
        <UserList role="Restreamers" users={restreamers} />
        <UserList role="Scouts" users={scouts} />
    </div>
}
