import { useAtomValue } from "jotai";
import { Racer } from "./racer";
import { racersAtom, racersOrderAtom } from "../../../state/racers";

export const RacerList = () => {
    const racers = useAtomValue(racersAtom)
    const racersOrder = useAtomValue(racersOrderAtom)
    
    if (Object.keys(racers).length === 0) {
        return <div className="racerList column">No signups.</div>
    }

    // TODO: Get <FlipMove /> working, there's an error when wrapping <Racer />s with it.
    return <div
        className="racerList column"
    >
        {
            racersOrder.map((racer) => <Racer racer={racers[racer]} key={racer} /> )
        }
        <p>
            {racersOrder.length} racers
        </p>
    </div>
}