import { atom } from "jotai";
import { Scout } from "../types/scout";

// Scout user state
export const scoutsAtom = atom<Record<string, Scout>>({})
export const scoutsListAtom = atom((get) => Object.values(get(scoutsAtom))
                                                    .map(scout => scout.username)
                                                    .sort())

// Scouting state
export const groupRacersAtom = atom(true)
export const isNotAuthenticatedInTwitchPlayerAtom = atom(false)
export const joinAllChatAtom = atom(false)
export const onlyShowRacerListAtom = atom(false)
export const refreshIntervalAtom = atom(10)
export const watchedStreamsAtom = atom<string[]>([])