import { Login } from './login'
import { useAtomValue } from "jotai"
import { RaceTypeSelect } from "./raceTypeSelect"
import { RoleTypeSelect } from "./roleTypeSelect"
import { loggedInAtom, raceTypeAtom } from '../../state/general'

import './intro.css'

export const Intro = () => {
    const isLoggedIn = useAtomValue(loggedInAtom)
    const raceType = useAtomValue(raceTypeAtom)

    if (!isLoggedIn) {
        return <Login />
    }

    if(!raceType){
        return <RaceTypeSelect />
    }

    return <RoleTypeSelect />
}