export const createRandomColor = () => {
	let result = '#';
	const characters = '0123456789abcdef';
	for (let i = 0; i < 6; i++ ) {
		result += characters.charAt(Math.floor(Math.random() * characters.length));
	}
	return result;
};

export const isColor = (text: string) => {
	const color = new Option().style;
	color.color = text;
	return color.color !== '';
}