export const createTimeString = (elapsedSeconds: number) => {
    const hours = Math.floor(elapsedSeconds / (1 * 60 * 60));
    elapsedSeconds -= hours * 60 * 60;

    const minutes = Math.floor(elapsedSeconds / (1 * 60));
    elapsedSeconds -= minutes * 60;

    const seconds = Math.floor(elapsedSeconds);

    return `${hours ? `${hours}:` : ''}${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
}