import { RacerList } from './racerList/racerList'
import { disconnectSocket, initiateSocket } from '../../../sockets'
import { useEffect } from 'react'
import { useAtomValue } from 'jotai'
import { raceTypeAtom, usernameAtom } from '../../state/general'

import './restream.css'

export const Restreaming = () => {
    const username = useAtomValue(usernameAtom)
    const raceType = useAtomValue(raceTypeAtom)

    useEffect(() => {
        initiateSocket({
            raceType,
            role: 'restream',
            username
        })

        return () => {
            disconnectSocket()
        }
    }, [])

    return <div className='restreamerContainer'>
        <div className='racerListWrapper'>
            <RacerList />
        </div>
    </div>
}